import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
import FlowGrid from '../../components/FlowGrid';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In this post we'll look at a very specific but tricky interaction in UIKit, one which took me multiple days to work out how to implement.`}</p>
    <p>{`It's two view controllers of different sizes, pushed on a `}<inlineCode parentName="p">{`UINavigationController`}</inlineCode>{`, which is presented as a popover. The interaction has a few unique qualities:`}</p>
    <ol>
      <li parentName="ol">{`The view controllers have different sizes (defined by autolayout)`}</li>
      <li parentName="ol">{`Only one of them displays a nav bar`}</li>
      <li parentName="ol">{`The popover animates when their autolayout-defined sizes change`}</li>
      <li parentName="ol">{`Including when they get pushed onto the nav stack, where the resize animation happens at the same time as the push animation`}</li>
    </ol>
    <CaptionedImage max={500} filename="popover-finished.gif" alt="Finished presentation of UIViewControllers with different content sizes inside a popover" caption="" mdxType="CaptionedImage" />
    <p>{`Surprisingly this is very tricky to implement in UIKit, and it ties together a lot of concepts that I wasn't really familiar with until I ran into this case.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The finished code for this interaction is available at `}<a parentName="p" {...{
          "href": "https://github.com/noahsark769/NGPopoverForceResizeTest"
        }}>{`NGPopoverForceResizeTest`}</a>{`. There's also a summary at the bottom of this post.`}</p>
    </blockquote>
    <h1 {...{
      "id": "motivation"
    }}>{`Motivation`}</h1>
    <p>{`At work, we do a fair amount of presenting view controllers as popovers to add context or more data to our existing iPad view. A common flow is the following:`}</p>
    <ol>
      <li parentName="ol">{`Present a small popover to add more context to something the user tapped.`}</li>
      <li parentName="ol">{`When the user taps on a button in that popover, push on a new view controller with more information.`}</li>
    </ol>
    <p>{`UIKit provides the building blocks for this out of the box, but the kicker is when `}<em parentName="p">{`the two view controllers are different sizes`}</em>{`.`}</p>
    <p>{`Let's assume we have two view controllers to present: one 300x300 with a red background and no navigation bar content, and one 600x400 with a green background and a nav bar title. Here's what we want them to look like:`}</p>
    <CaptionedImage max={300} filename="red-controller.png" alt="Red view controller" caption="SmallViewControllerNoNavBar" mdxType="CaptionedImage" />
    <CaptionedImage max={500} filename="green-controller.png" alt="Green view controller" caption="LargeViewControllerWithNavBar" mdxType="CaptionedImage" />
    <h1 {...{
      "id": "preferredcontentsize"
    }}>{`preferredContentSize`}</h1>
    <p>{`Let's start with the red controller.`}</p>
    <p>{`Setting up the width and height is doable with autolayout:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewDidLoad`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`widthAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalToConstant`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`300`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`heightAnchor`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`constraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`equalToConstant`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`300`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ).`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isActive`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We'll set the navigation bar hidden in `}<inlineCode parentName="p">{`viewWillAppear`}</inlineCode>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewWillAppear`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewWillAppear`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(animated)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`navigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setNavigationBarHidden`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And, from the main controller, present our red view as a popover:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` containerController = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UINavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: firstVC)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`containerController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`modalPresentationStyle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`popover`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... set the sourceView, sourceRect, etc`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`present`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(containerController, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`Unfortunately, this doesn't exactly produce the effect we're going for:`}</p>
    <CaptionedImage max={500} filename="red-controller-1.png" alt="Red view controller displaying in a popover with the wrong height and width" caption="Interesting" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "preferredcontentsize--autolayout"
    }}>{`preferredContentSize + autolayout`}</h2>
    <p>{`Our red view controller doesn't have the right size because when `}<inlineCode parentName="p">{`UIViewController`}</inlineCode>{`s are presented as popovers, the popover's size is `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/uiviewcontroller/1621476-preferredcontentsize"
      }}>{`determined by the controller's `}<inlineCode parentName="a">{`preferredContentSize`}</inlineCode></a>{`. Since we haven't set `}<inlineCode parentName="p">{`self.preferredContentSize`}</inlineCode>{` in our controller, the system uses the default: on iOS 12 with a 12.9 inch iPad, this is 375x636 points.`}</p>
    <p>{`To fix this, we have to trigger a layout pass to determine our autolayout-defined size, and we can set that as our preferred size, as many posts online `}<a parentName="p" {...{
        "href": "https://useyourloaf.com/blog/self-sizing-child-views/"
      }}>{`have`}</a>{` `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/29128271/autolayout-and-uipopovercontroller"
      }}>{`detailed`}</a>{`:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewWillAppear`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`systemLayoutSizeFitting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        UIView.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layoutFittingCompressedSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Assuming we've implemented the same thing in our green view controller (with different height and width), we now get our controllers displaying their correct sizes! `}</p>
    <p>{`Unfortunately, the animation is a little weird - our green controller starts out 300x300, `}<em parentName="p">{`then`}</em>{` transitions to 600x400:`}</p>
    <CaptionedImage max={500} filename="popover-animation-1.gif" alt="Red view controller with the right size, pushing on a green view controller, whose size starts the same as the red controller but expands when finished pushing." caption="Also interesting" mdxType="CaptionedImage" />
    <h1 {...{
      "id": "uinavigationcontroller-animations"
    }}>{`UINavigationController animations`}</h1>
    <p>{`Apparently, `}<inlineCode parentName="p">{`UINavigationController`}</inlineCode>{` queues the animations for pushing on a new view controller and setting its own `}<inlineCode parentName="p">{`preferredContentSize`}</inlineCode>{` based on the controller being pushed, so the push animation and the resize animation happen in series. Ideally, we'd like these animations to happen simultaneously.`}</p>
    <p>{`After quite a bit of spelunking online, I realized that `}<inlineCode parentName="p">{`UINavigationController`}</inlineCode>{` just doesn't support this use case. However, I stumbled across `}<a parentName="p" {...{
        "href": "https://github.com/fatuhoku/Resize-Popover-with-embedded-UINavigationController"
      }}>{`this example`}</a>{` by `}<a parentName="p" {...{
        "href": "http://hokshunpoon.me/"
      }}>{`Hok Shun Poon`}</a>{`, which noted that you can get the resize to happen at the same time if you encapsulate the `}<inlineCode parentName="p">{`UINavigationController`}</inlineCode>{` in a parent view controller!`}</p>
    <p>{`The flow looks like this:`}</p>
    <ol>
      <li parentName="ol">{`We have a parent `}<inlineCode parentName="li">{`UIViewController`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://useyourloaf.com/blog/container-view-controllers/"
        }}>{`wrapping`}</a>{` our `}<inlineCode parentName="li">{`UINavigationController`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`When the new controller appears, we'll set the wrapper view controller's `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{` to mirror the child's `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{` using `}<inlineCode parentName="li">{`popoverPresentationController`}</inlineCode>{`.`}</li>
    </ol>
    <h2 {...{
      "id": "wrapping-the-nav-controller"
    }}>{`Wrapping the nav controller`}</h2>
    <p>{`Our wrapper controller looks like this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`PopoverPushController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` wrappedNavigationController: PopoverPushNavigationController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`wrappedNavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`PopoverPushNavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: rootViewController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`nibName`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bundle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`wrappedNavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`delegate`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewDidLoad`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewDidLoad`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        wrappedNavigationController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`willMove`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`toParent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addChild`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(wrappedNavigationController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addSubview`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(wrappedNavigationController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`When our container controller's view loads, we add the navigation controller's view as a subview, after using `}<inlineCode parentName="p">{`willMove(toParent:)`}</inlineCode>{` and `}<inlineCode parentName="p">{`addChild()`}</inlineCode>{` to `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/uiviewcontroller/1621381-willmove"
      }}>{`add the navigation controller as a child view controller`}</a>{`.`}</p>
    <p>{`We also need the implementation of `}<inlineCode parentName="p">{`viewWillAppear`}</inlineCode>{` to set the wrapper controller's content size. Luckily we can access the wrapper controller using `}<inlineCode parentName="p">{`popoverPresentationController`}</inlineCode>{`'s `}<inlineCode parentName="p">{`presentedViewController`}</inlineCode>{`, even though it's two levels up:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewWillAppear`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ...`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` contentSize = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`systemLayoutSizeFitting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        UIView.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layoutFittingCompressedSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = contentSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`popoverPresentationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`presentedViewController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = contentSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And finally, we'll change our presentation code to present the `}<inlineCode parentName="p">{`PopoverPushController`}</inlineCode>{` wrapper instead of the regular navigation controller:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` containerController = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`PopoverPushController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: firstVC)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`containerController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`modalPresentationStyle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`popover`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// ... set the sourceView, sourceRect, etc`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`present`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(containerController, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`animated`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <p>{`No we have the simultaneous animation we want!`}</p>
    <CaptionedImage max={500} filename="popover-animation-2.gif" alt="Red view controller transitioning to green view controller with size animating at the same time as the push animation." caption="🎉" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "updating-the-size"
    }}>{`Updating the size`}</h2>
    <p>{`Everything's well and good now, so we can implement the last part of the interaction: resizing the green controller on button tap. Our code isn't particularly complicated, but we'll use the same autolayout technique from the red controller:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setPreferredContentSizeFromAutolayout`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`systemLayoutSizeFitting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        UIView.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layoutFittingCompressedSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` isExpanded: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`didSet`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` constant = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isExpanded`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` ? `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`600`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` : `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`400`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`heightConstraint`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`constant`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = constant`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setPreferredContentSizeFromAutolayout`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@objc`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`didTap`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isExpanded`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = !`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isExpanded`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`But now we've got a new problem: the popover size doesn't animate!`}</p>
    <CaptionedImage max={500} filename="popover-animation-3.gif" alt="Green view controller updating height, but without animation." caption="We were expecting a smooth animation" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "informing-the-presented-view-controller"
    }}>{`Informing the presented view controller`}</h2>
    <p>{`The issue we have here is that our green view controller is changing its `}<inlineCode parentName="p">{`preferredContentSize`}</inlineCode>{`, but `}<em parentName="p">{`the popover view controller doesn't know it changed`}</em>{`. We'll use the same technique that we used for setting the popover view controller's content size when the green view initially appeared (using `}<inlineCode parentName="p">{`popoverPresentationController`}</inlineCode>{`):`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`setPreferredContentSizeFromAutolayout`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` contentSize = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`systemLayoutSizeFitting`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        UIView.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`layoutFittingCompressedSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = contentSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`popoverPresentationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`presentedViewController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`preferredContentSize`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = contentSize`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Now our interaction finally works as expected!`}</p>
    <CaptionedImage max={500} filename="popover-finished.gif" alt="Finished presentation of UIViewControllers with different content sizes inside a popover" caption="🎉🎉" mdxType="CaptionedImage" />
    <h1 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h1>
    <p>{`Hopefully this was a helpful look into the world of `}<inlineCode parentName="p">{`preferredContentSize`}</inlineCode>{`, view controller wrapping, and UIKit popovers. Here's a tl;dr:`}</p>
    <ol>
      <li parentName="ol">{`To define the size of your popover with autolayout, set `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{` to the result of `}<inlineCode parentName="li">{`systemLayoutSizeFitting`}</inlineCode></li>
      <li parentName="ol">{`To animate popover size updates at the same time as navigation controller animations, wrap your `}<inlineCode parentName="li">{`UINavigationController`}</inlineCode>{` in a `}<inlineCode parentName="li">{`PopoverPushController`}</inlineCode>{` (see code below)`}</li>
      <li parentName="ol">{`When you change your controller's `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{`, be sure to change the `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{` of your controller's `}<inlineCode parentName="li">{`popoverPresentationController`}</inlineCode>{`'s `}<inlineCode parentName="li">{`presentedViewController`}</inlineCode>{` as well`}</li>
    </ol>
    <p>{`Though this solution takes some time to explain, I think it ends up being pretty clean:`}</p>
    <ol>
      <li parentName="ol">{`No `}<inlineCode parentName="li">{`UINavigationController`}</inlineCode>{` subclass 🎉`}</li>
      <li parentName="ol">{`No need to implement `}<inlineCode parentName="li">{`UINavigationControllerDelegate`}</inlineCode>{` - you can even modify `}<inlineCode parentName="li">{`PopoverPushController`}</inlineCode>{` to accept your own navigation controller, along with your own delegate 😮`}</li>
      <li parentName="ol">{`No need to set `}<inlineCode parentName="li">{`preferredContentSize`}</inlineCode>{` except when the size actually changes ✅`}</li>
      <li parentName="ol">{`If you make the width and height constraints have `}<inlineCode parentName="li">{`priority = .defaultHigh`}</inlineCode>{`, no unsatisfiable constraint warnings clogging your logs 💪`}</li>
    </ol>
    <p>{`With more plumbing, it's possible to implement the same interaction without having to call into `}<inlineCode parentName="p">{`self.popoverPresentationController`}</inlineCode>{` - you can have a `}<inlineCode parentName="p">{`UINavigationController`}</inlineCode>{` subclass implement `}<inlineCode parentName="p">{`preferredContentSizeWillChange`}</inlineCode>{`, and have it set the navgation controller's content size, which will be intercepted by `}<inlineCode parentName="p">{`PopoverPushController`}</inlineCode>{`'s `}<inlineCode parentName="p">{`preferredContentSizeWillChange`}</inlineCode>{`. You can also provide a `}<inlineCode parentName="p">{`UINavigationControllerDelegate`}</inlineCode>{` to avoid setting the popover controller's content size in `}<inlineCode parentName="p">{`viewWillAppear`}</inlineCode>{`.`}</p>
    <p>{`Ultimately though, it's better to do whatever feels more workable for your use case. I prefer using the `}<inlineCode parentName="p">{`popoverPresentationController`}</inlineCode>{` because it feels cleaner to me, but if you think differently, `}<a parentName="p" {...{
        "href": "https://twitter.com/noahsark769"
      }}>{`let me know`}</a>{`.`}</p>
    <p>{`If you're interested in learning more about what I've built with UIKit (like `}<a parentName="p" {...{
        "href": "/blog/uibutton-padding"
      }}>{`the surprising complexity in adding padding to a UIButton`}</a>{`), you can `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://twitter.com/noahsark769"
        }}>{`follow me on Twitter`}</a></strong>{`.`}</p>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <p>{`All the code is available at `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGPopoverForceResizeTest"
      }}>{`NGPopoverForceResizeTest`}</a>{`.`}</p>
    <p>{`Here's the full implementation of the nav controller wrapper:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`final`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`PopoverPushController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` wrappedNavigationController: UINavigationController`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: UIViewController) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`wrappedNavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`UINavigationController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`rootViewController`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: rootViewController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`nibName`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bundle`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`required`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init?`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`coder`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`aDecoder`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NSCoder) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fatalError`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"init(coder:) has not been implemented"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewDidLoad`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`viewDidLoad`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        wrappedNavigationController.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`willMove`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`toParent`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addChild`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(wrappedNavigationController)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`addSubview`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(wrappedNavigationController.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`view`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      